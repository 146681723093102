@import "../../App.scss";

.acc-settings-container {
     display: flex;
     flex-direction: column;
     padding: 20px;
     background-color: #1d1d1d; /* Dark background color */
     border-radius: 8px;
     width: 100%;
     max-width: 600px;
     margin: auto;
     color: #e0e0e0; /* Light text color for contrast */
     position: relative;
     
   }
   
   .acc-settings-container.blurred::before {
     content: "";
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background: rgba(0, 0, 0, 0.6); /* Dark overlay */
     filter: blur(8px); /* Blur effect */
     z-index: 999; /* Behind the popup */
   }
   
   .acc-settings-container.blurred .blocked-users-popup {
     position: fixed;
     top: 35%;
     left: 55%;
     transform: translateX(-50%);
     background-color: rgb(44, 44, 44); /* Dark, slightly transparent background for popup */
     padding: 20px;
     border-radius: 8px;
     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Darker shadow for better contrast */
     width: 30%;
     max-height: 400px;
     overflow-y: auto;
     z-index: 1000; /* On top of the blur */
     color: #e0e0e0; /* Light text color for popup */
   }
   
   .acc-settings-heading {
     font-size: 24px;
     margin-bottom: 20px;
     text-align: center;
   }
   
   .settings-section {
     margin-bottom: 20px;
     padding-bottom: 20px;
     border-bottom: 1px solid #333; /* Border below each section */
   }
   
   .section-heading {
     font-size: 20px;
     margin-bottom: 10px;
     color: #f0f0f0; /* Light text color */
   }
   
   .email-id-container {
     margin-bottom: 10px;
   }
   
   .email-id-heading {
     font-size: 16px;
   }
   
   .email-id-text {
    font-size: 18px; /* Larger text for more impact */
    font-weight: bold; /* Emphasizes the text */
    color: white; /* Makes the gradient visible */
    letter-spacing: 1px; /* Adds space between letters for style */
    transition: transform 0.3s ease, text-shadow 0.3s ease; /* Smooth animation on hover */
    cursor: pointer; /* Show that it’s interactive */

}

  
   
   .change-password-btn, .view-blocked-users-btn, .help-btn, .about-btn {
     background-color: #007bff;
     color: #fff;
     padding: 10px 20px;
     border: none;
     border-radius: 4px;
     cursor: pointer;
     font-size: 16px;
     display: block;
     margin-top: 10px;
     text-align: center;
     text-decoration: none;
   }
   
   .change-password-btn:hover, .view-blocked-users-btn:hover {
     background-color: #0056b3;
   }
   
   .help-btn:hover, .about-btn:hover {
     background-color: #0056b3;
   }
   
   .password-fields-container {
     margin-top: 10px;
   }
   
   .password-input {
     display: block;
     width: 95%;
     padding: 10px;
     margin-bottom: 10px;
     border: 1px solid #333; /* Dark border color */
     border-radius: 4px;
     background-color: #2a2a2a; /* Dark input background */
     color: #e0e0e0; /* Light text color */
   }
   
   .password-input::placeholder {
     color: #888; /* Lighter placeholder text color */
   }
   
   .save-btn {
     background-color: #28a745;
     color: #fff;
     padding: 10px 20px;
     border: none;
     border-radius: 4px;
     cursor: pointer;
   }
   
   .save-btn:hover {
     background-color: #218838;
   }
   
   .forgot-password-text {
     margin-top: 10px;
     text-align: center;
     color: rgb(88, 88, 255);
     cursor: pointer;
   }
   
   .blocked-users-popup {
     position: relative; /* Ensures the popup itself is clear */
   }
   
   .close-popup-btn {
     background-color: #f1152b56;
     color: #fff;
     border: none;
     border-radius: 10%;
     cursor: pointer;
     position: absolute;
     top: 10px;
     right: 10px;
     font-size: 15px;
   }
   
   .popup-heading {
     font-size: 18px;
     margin-bottom: 10px;
   }
   
   .blocked-users-list {
     list-style-type: none;
     padding: 0;
   }
   
   .blocked-user-item {
     display: flex;
     align-items: center;
     padding: 10px 0;
     border-bottom: 1px solid #444; /* Darker border for list items */
   }
   
   .profile-pic {
     width: 40px;
     height: 40px;
     border-radius: 50%;
     margin-right: 10px;
   }
   
   .user-email {
     flex: 1;
   }
   
   .unblock-btn {
     background-color: #dc3545;
     color: #fff;
     border: none;
     padding: 5px 10px;
     border-radius: 4px;
     cursor: pointer;
     font-size: 14px;
   }
   
   .unblock-btn:hover {
     background-color: #c82333;
   }
   

   .password-buttons {
    display: flex; /* Aligns buttons horizontally */
    justify-content: flex-start; /* Align to the left */
    align-items: center; /* Vertically center the buttons */
    gap: 10px; /* Adds space between the buttons */
  }
  
  .change-password-btn {
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 5px;
  }
  
  .show-password-btn {
    color: #fff;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center; /* Align icon inside button */
    margin-left: auto;
    margin-right: 15px;
  }
  
  .show-password-btn img {
    width: 20px; /* Adjust image size if necessary */
    height: 20px;
  }
  
  

   @include mobile {
     .acc-settings-container.blurred .blocked-users-popup {
       top: 30%;
       left: 50%;
       transform: translateX(-50%);
       width: 90%;
       max-height: 80%;
     }
     .acc-settings-container {
      height: 100vh;
     }
   
     .close-popup-btn {
       font-size: 14px;
     }
   
     .profile-pic {
       width: 30px;
       height: 30px;
     }
   
     .popup-heading {
       font-size: 16px;
     }
   
     .unblock-btn {
       font-size: 12px;
     }
   }
   
   /* Tablet landscape view */
   @include tablet-landscape {
     .acc-settings-container.blurred .blocked-users-popup {
       top: 30%;
       left: 55%;
       transform: translateX(-50%);
       width: 50%;
       max-height: 60%;
     }
   
     .close-popup-btn {
       font-size: 16px;
     }
   
     .profile-pic {
       width: 35px;
       height: 35px;
     }
   
     .popup-heading {
       font-size: 17px;
     }
   
     .unblock-btn {
       font-size: 13px;
     }
   }
   
   /* Tablet portrait view */
   @include tablet-portrait {
     .acc-settings-container.blurred .blocked-users-popup {
       top: 30%;
       left: 50%;
       transform: translateX(-50%);
       width: 60%;
       max-height: 70%;
     }
     .acc-settings-container {
      height: 100vh;
     }
   
     .close-popup-btn {
       font-size: 16px;
     }
   
     .profile-pic {
       width: 35px;
       height: 35px;
     }
   
     .popup-heading {
       font-size: 17px;
     }
   
     .unblock-btn {
       font-size: 13px;
     }
   }

   .contact-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); // Dark overlay
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  
    &__content {
      background-color: #1e1e1e; // Dark background
      padding: 20px;
      border-radius: 8px;
      max-width: 400px;
      width: 90%;
      position: relative;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5); // Shadow for depth
      color: #f0f0f0; // Light text color
    }
  
    .close-popup-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      font-size: 24px;
      color: #f0f0f0; // Light text color
      cursor: pointer;
      transition: color 0.3s; // Transition effect
  
      &:hover {
        color: #fe7f2d; // Highlight color on hover
      }
    }
  
    .popup-heading {
      margin-top: 0;
      font-size: 1.5em; // Larger font for headings
      color: #fe7f2d; // Highlight color for heading
    }
  
    .email-container {
      display: flex;
      margin-top: 20px;
      border: 1px solid #444; // Border around the email input
  
      input {
        flex-grow: 1;
        padding: 10px; // Increased padding for better touch
        border: none; // Remove default border
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        background-color: #333; // Dark background for input
        color: #f0f0f0; // Light text color
        transition: background-color 0.3s; // Transition for focus effect
        font-size: 18px;
  
        &:focus {
          outline: none; // Remove default outline
          background-color: #444; // Darker background on focus
        }
      }
  
      button {
        padding: 10px; // Increased padding
        background-color: #fe7f2d; // Highlight color for button
        color: white;
        border: none; // Remove default border
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s; // Transition for hover effect
  
        &:hover {
          background-color: #e66b2d; // Darker shade on hover
        }
      }
    }
  }
  
  .blurred {
    filter: blur(5px);
  }
  