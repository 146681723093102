@import "../../App.scss";
// Break

.postCP {
  padding: 0;
  flex: 1 1 300px;
  max-width: 50vh;
  min-width: 350px;
  margin-bottom: 2vw;
  text-decoration: none;

  @include ipad-pro {
    max-width: 40vh;
    min-width: 600px;
  }

  @include ipad-mini {
    max-width: 40vh;
    min-width: 350px;        
  }

  @include tablet-portrait {
    max-width: 40vh;
    min-width: 600px; 
  }

  @include ipad-air {
    max-width: 40vh;
    min-width: 600px;   
  }

  .postElementCP {
    padding: 0.5vh;
    background-color: rgb(30, 30, 30);
    border-radius: 1vh;
    width: 100%;
    height: 50vh;  // This may be changed based on your needs
    position: relative;
    transition: transform 0.3s ease-in-out; // add transition for hover effect
    cursor: pointer;

    &:hover {
      transform: scale(1.02); // scale up on hover
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); // add box shadow on hover
    }

    .wrapCP {
      display: flex;
      flex-direction: column;
      position: relative;

      .postImgCP {
        width: 100%;
        height: 25vh;
      }

      .profDetailsCP {
        background-color: rgba(0, 0, 0, 0.552);
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        margin-top: 1vh;
        gap: 1.7vh;
        align-items: center;
        padding-left: 1vh;
        flex-grow: 20;
        width: inherit;
        padding-right: 3vh;
        padding-top: 0.5vh;
        padding-bottom: 0.1vh;
        border-radius: 0 5vh 5vh 0;

        .profPicCP {
          width: 5.5vh;
          height: 5.5vh;
          border-radius: 50%;
          object-fit: cover;
        }

        .userNameCP {
          font-size: 1.5vh;
        }

        .timeCP {
          font-size: 1vh;
        }
      }
    }

    .textContentCP {
      display: flex;
      flex-direction: column;
      max-height: calc(50vh - 30vh); // Restrict height based on footer space
      overflow: hidden; // Prevent overflow
      justify-content: flex-start; // Align text at the top
     
      .postTitleCP {
        font-size: 2.5vh;
        margin-bottom: 0;
        color: white !important;
      }

      .postContentCP {
        font-size: 1.8vh;
        overflow-y: auto; // Allow scrolling if content exceeds the limit
      
        /* Scrollbar styles */
        &::-webkit-scrollbar {
          width: 2px; /* width of the scrollbar */
          height: 12px; /* height of the scrollbar */
        }
      
        &::-webkit-scrollbar-track {
          background: #f1f1f1; /* scrollbar track background color */
          border-radius: 10px; /* rounded corners */
        }
      
        &::-webkit-scrollbar-thumb {
          background: #888; /* scrollbar thumb background color */
          border-radius: 10px; /* rounded corners */
        }
      
        &::-webkit-scrollbar-thumb:hover {
          background: #555; /* scrollbar thumb hover background color */
        }
      }
    }
  


       @include ipad-air {
        .postTitleCP {
          font-size: 2vh;
          margin-bottom: 0;
          color: white !important;
        }
    
        .postContentCP {
          font-size: 1.7vh;
        }
      }
    
       }
   
       
   
       .postFooterCP {
          display: flex;
          gap: 2vh;
          align-items: center;
          position: absolute;
          bottom: 0.5vh;
          left: 0;
          right: 0;
          padding: 1vh 2vh;
          width: auto;
          flex-wrap: wrap;
          justify-content: space-between;
   
         .likeDivCP {
          flex: 0 1 auto;
          display: flex;
          align-items: center;
          gap: 0.5vh;
   
           .likeIconCP {
             width: 3.5vh;
             height: 3.5vh;
             transition: transform 0.3s ease-in-out; // add transition for hover effect
   
             &:hover {
               transform: scale(1.2); // scale up on hover
             }
           }
   
           .likesCountCP {
            font-size: 1.5vh;
           }
         }
   
         .viewsDivCP {
          flex: 0 1 auto;
          display: flex;
          align-items: center;
          gap: 0.5vh;
   
           .viewsIconCP {
             width: 3.5vh;
             height: 3.5vh;
           }
   
           .viewsCountCP {
            font-size: 1.5vh;
           }
         }
   
         .shareCP {
           align-items: center;
           width: 2vh;
           height: 2.5vh;
           flex: 0 0 auto;

           &:hoverCP {
            transform: scale(1.2); // scale up on hover
          }
         }
   
         .saveCP {
           width: 2.5vh;
           height: 2.7vh;
           margin-left: auto;
           flex: 0 0 auto;
           transition: transform 0.3s ease-in-out; // add transition for hover effect
   
           &:hover {
             transform: scale(1.2); // scale up on hover
           }
         }
       }
    

 
     @include mobile {
      .postCP {
        padding: 1.2vh;
        padding-right:  1vh;
        
        
           
        .postElementCP {
          
          width: 40vh;
          height: 40vh;
  
          .wrapCP {
            .postImgCP {
              width: 100%;
              height: 20vh;
    
            }
      
            .profDetailsCP {
               
              .profPicCP {
                width: 4.5vh;
                height: 4.5vh;
                border-radius: 50%;
              }
      
              .userNameCP {
                font-size: 1.5vh;
              }
      
              .timeCP {
                font-size: 1vh;
              }
            }
          }
   
          .textContentCP{
    
            
           .postTitleCP {
            font-size: 1.6vh;
            font-weight: 900;
           }
      
          .postContentCP {
            font-size: 1.5vh;
          }
          }
      
          
      
          .postFooterCP {
            gap: 3vh;
      
            .likeDivCP {
            
              gap: 0.5vh;
      
              .likeIconCP {
                width: 3.5vh;
                height: 3.5vh;
                transition: transform 0.3s ease-in-out; // add transition for hover effect
      
                &:hover {
                  transform: none; // scale up on hover
                }
              }
      
              .likesCountCP {
               font-size: 1.5vh;
              }
            }
      
            .viewsDivCP {
              
              gap: 0.5vh;
      
              .viewsIconCP {
                width: 3.5vh;
                height: 3.5vh;
              }
      
              .viewsCountCP {
               font-size: 1.5vh;
              }
            }
      
            .shareCP {
              align-items: center;
              width: 2vh;
              height: 2.5vh;
   
              &:hover {
               transform: none; // scale up on hover
             }
            }
      
            .saveCP {
              width: 2.5vh;
              height: 2.7vh;
              margin-left: auto;
              transition: transform 0.3s ease-in-out; // add transition for hover effect
      
              &:hover {
                transform: none; // scale up on hover
              }
            }
          }
        }
   
   
        
      }
     }
    }












// If in case the post makes any error while resizing use this code!


// @import "../../App.scss";
// // Break

// .postCP {
//      padding: 0;
//      flex: 1 1 300px;
//      max-width: 50vh;
//      min-width: 250px;
//      margin-bottom: 2vw;
//      text-decoration: none;
        
//      @include ipad-pro {
           
//           max-width: 40vh;
//           min-width: 450px;
//         }
      
//         @include ipad-mini {
          
//           max-width: 40vh;
//           min-width: 350px;        
//       }

//      .postElementCP {
//        padding: 0.5vh;
//        background-color: rgb(30, 30, 30);
//        border-radius: 1vh;
//        width: 100%;
//        height: 50vh;
//        position: relative;
//        transition: transform 0.3s ease-in-out; // add transition for hover effect
//        cursor: pointer;
 
     
   
//        &:hover {
//          transform: scale(1.02); // scale up on hover
//          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); // add box shadow on hover
//        }
   
//        .wrapCP {
//          display: flex;
//          flex-direction: column;
//          position: relative;
   
//          .postImgCP {
//            width: 100%;
//            height: 25vh;
 
//          }
   
//          .profDetailsCP {
//            background-color: rgba(0, 0, 0, 0.552);
//            position: absolute;
//            bottom: 0;
//            left: 0;
//            display: flex;
//            margin-top: 1vh;
//            gap: 1.7vh;
//            align-items: center;
//            padding-left: 1vh;
//            flex-grow: 20;
//            width: inherit;
//            padding-right: 3vh;
//            padding-top: 0.5vh;
//            padding-bottom: 0.1vh;
//            border-radius: 0 5vh 5vh 0;

   
//            .profPicCP {
//              width: 5.5vh;
//              height: 5.5vh;
//              border-radius: 50%;
//            }
   
//            .userNameCP {
//              font-size: 1.5vh;
//            }
   
//            .timeCP {
//              font-size: 1vh;
//            }
//          }
//        }

//        .textContentCP{

//         display: flex;
//         flex-direction: column;    
//         // max-width: 95%;
//         // position: absolute;
        
         
//         .postTitleCP {
//          font-size: 2.5vh;
//          margin-bottom: 0;
//          color: white !important;
//        }
   
//        .postContentCP {
//          font-size: 1.8vh;
//        }
//        }
   
       
   
//        .postFooterCP {
//           display: flex;
//           gap: 2vh;
//           align-items: center;
//           position: absolute;
//           bottom: 0.5vh;
//           left: 0;
//           right: 0;
//           padding: 1vh 2vh;
//           width: auto;
//           flex-wrap: wrap;
//           justify-content: space-between;
   
//          .likeDivCP {
//           flex: 0 1 auto;
//           display: flex;
//           align-items: center;
//           gap: 0.5vh;
   
//            .likeIconCP {
//              width: 3.5vh;
//              height: 3.5vh;
//              transition: transform 0.3s ease-in-out; // add transition for hover effect
   
//              &:hover {
//                transform: scale(1.2); // scale up on hover
//              }
//            }
   
//            .likesCountCP {
//             font-size: 1.5vh;
//            }
//          }
   
//          .viewsDivCP {
//           flex: 0 1 auto;
//           display: flex;
//           align-items: center;
//           gap: 0.5vh;
   
//            .viewsIconCP {
//              width: 3.5vh;
//              height: 3.5vh;
//            }
   
//            .viewsCountCP {
//             font-size: 1.5vh;
//            }
//          }
   
//          .shareCP {
//            align-items: center;
//            width: 2vh;
//            height: 2.5vh;
//            flex: 0 0 auto;

//            &:hoverCP {
//             transform: scale(1.2); // scale up on hover
//           }
//          }
   
//          .saveCP {
//            width: 2.5vh;
//            height: 2.7vh;
//            margin-left: auto;
//            flex: 0 0 auto;
//            transition: transform 0.3s ease-in-out; // add transition for hover effect
   
//            &:hover {
//              transform: scale(1.2); // scale up on hover
//            }
//          }
//        }
//      }


     
//    }

 
//      @include mobile {
//       .postCP {
//         padding: 1.2vh;
//         padding-right:  1vh;
        
        
           
//         .postElementCP {
          
//           width: 40vh;
//           height: 40vh;
  
//           .wrapCP {
//             .postImgCP {
//               width: 100%;
//               height: 20vh;
    
//             }
      
//             .profDetailsCP {
               
//               .profPicCP {
//                 width: 4.5vh;
//                 height: 4.5vh;
//                 border-radius: 50%;
//               }
      
//               .userNameCP {
//                 font-size: 1.5vh;
//               }
      
//               .timeCP {
//                 font-size: 1vh;
//               }
//             }
//           }
   
//           .textContentCP{
    
            
//            .postTitleCP {
//             font-size: 1.6vh;
//             font-weight: 900;
//            }
      
//           .postContentCP {
//             font-size: 1.5vh;
//           }
//           }
      
          
      
//           .postFooterCP {
//             gap: 3vh;
      
//             .likeDivCP {
            
//               gap: 0.5vh;
      
//               .likeIconCP {
//                 width: 3.5vh;
//                 height: 3.5vh;
//                 transition: transform 0.3s ease-in-out; // add transition for hover effect
      
//                 &:hover {
//                   transform: none; // scale up on hover
//                 }
//               }
      
//               .likesCountCP {
//                font-size: 1.5vh;
//               }
//             }
      
//             .viewsDivCP {
              
//               gap: 0.5vh;
      
//               .viewsIconCP {
//                 width: 3.5vh;
//                 height: 3.5vh;
//               }
      
//               .viewsCountCP {
//                font-size: 1.5vh;
//               }
//             }
      
//             .shareCP {
//               align-items: center;
//               width: 2vh;
//               height: 2.5vh;
   
//               &:hover {
//                transform: none; // scale up on hover
//              }
//             }
      
//             .saveCP {
//               width: 2.5vh;
//               height: 2.7vh;
//               margin-left: auto;
//               transition: transform 0.3s ease-in-out; // add transition for hover effect
      
//               &:hover {
//                 transform: none; // scale up on hover
//               }
//             }
//           }
//         }
   
   
        
//       }
//      }
   