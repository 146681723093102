@import "../../App.scss";


.editProfileCont {
     display: flex;
     justify-content: center;
     text-align: center;
     height: 100%;   
     .editProfileWrap {
       width: 100%;
       max-width: 400px;
       background-color: #333;
       border-radius: 8px;
       padding: 20px;
       box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

       .EPText {
          text-align: center;
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 20px;
        }
     }
   
     .editProfPic {
       position: relative;
       width: 120px;
       height: 120px;
       margin: 0 auto;
       margin-top: 20px;
   
       img {
         width: 100%;
         height: 100%;
         border-radius: 50%;
         object-fit: cover;
         cursor: pointer;
       }
   
       .changeProfPicButton {
         position: absolute;
         bottom: 0;
         right: 0;
         background-color: #fff;
         color: #1d1d1d;
         border-radius: 50%;
         padding: 8px;
         cursor: pointer;
         box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
       }
     }
   
     .edituserName,
     .editLocation,
     .editProfession {
       margin: 20px 0;
   
       input {
         width: 95%;
         padding: 10px;
         border: 1px solid #555;
         border-radius: 4px;
         font-size: 16px;
         background-color: #1d1d1d;
         color: #fff;
       }
     }
   
     .editInterest {
       margin: 25px 0;
   
       .interestList {
         display: flex;
         flex-wrap: wrap;
         gap: 10px;
         margin-bottom: 10px;
   
         .interestItem {
           background-color: #555;
           padding: 8px 12px;
           border-radius: 16px;
           display: flex;
           align-items: center;
           font-size: 14px;
           color: #fff;
   
           button {
             margin-left: 8px;
             background: none;
             border: none;
             cursor: pointer;
             font-size: 16px;
             color: #ccc;
           }
         }
       }
   
       input {
         width: 99%;
         padding: 10px;
         border: 1px solid #555;
         border-radius: 4px;
         font-size: 16px;
         background-color: #1d1d1d;
         color: #fff;

         @include mobile {
          width: 95%;
         }
         @include tablet-portrait {
          width: 95%;
         }
         @include tablet-landscape {
          width: 95%;
         }
       }
     }
   
     .saveChangesBtn {
       display: flex;
       justify-content: center;
   
       button {
         padding: 10px 20px;
         font-size: 16px;
         background-color: #007bff;
         color: #fff;
         border: none;
         border-radius: 4px;
         cursor: pointer;
         transition: background-color 0.3s ease;
   
         &:hover {
           background-color: #0056b3;
         }
       }
     }
   }
   