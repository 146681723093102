.create-post {
     max-width: 700px;
     margin: 0 auto;
     padding: 20px;
     border-radius: 8px;
     background-color: #f9f9f9;
     box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   
     &.dark-mode {
       background-color: #333;
       color: #fff;
   
       .post-title,
       .post-content,
       .category-input,
       .category-dropdown {
         background-color: #444;
         color: #fff;
         border-color: #555;
       }
   
       .image-preview .image-container img {
         border-color: #555;
       }
   
       .category-dropdown {
         background-color: #444;
         border-color: #555;
         scrollbar-width: thin;
         scrollbar-color: #cdcccc #f6f5f5;
   
         li {
           background-color: #ffffff;
           color: #000000;
   
           &:hover {
             background-color: #555;
           }
         }
       }
   
       .dropdown-arrow {
         background-color: #555;
         color: #fff;
   
         &:hover {
           background-color: #666;
         }
       }

       .quill {
        .ql-toolbar {
          background-color: #444;
          border-color: #555;
  
          .ql-stroke {
            stroke: #fff;
          }
  
          .ql-fill {
            fill: #fff;
          }
  
          .ql-picker {
            color: #fff;
  
            &-options {
              background-color: #444;
            }
  
            &-label {
              color: #fff;
            }
          }
  
          button:hover,
          button:focus,
          button.ql-active,
          .ql-picker-label:hover,
          .ql-picker-label.ql-active,
          .ql-picker-item:hover,
          .ql-picker-item.ql-selected {
            color: #fff;
            background-color: #555;
          }
        }
  
        .ql-container {
          background-color: #444;
          border-color: #555;
          color: #fff;
          font-family: 'Times New Roman', Times, serif;
        }
  
        .ql-editor {
          color: #fff;
        }
      }
     }
   
     &.light-mode {
       background-color: #f2f4f3;
       color: #000;
   
       .post-title,
       .post-content,
       .category-input,
       .category-dropdown {
         background-color: #f2f4f3;
         color: #000;
         border-color: #ccc;
       }
   
       .image-preview .image-container img {
         border-color: #ccc;
       }
   
       .category-dropdown {
         background-color: #fff;
         border-color: #ccc;
   
         li {
           background-color: #fff;
           color: #000;
   
           &:hover {
             background-color: #f0f0f0;
           }
         }
       }
   
       .dropdown-arrow {
         background-color: #e9e9e9;
         color: #000;
   
         &:hover {
           background-color: #d4d4d4;
         }
       }
     }
   
     .toggle-mode {
       float: right;
       padding: 5px 10px;
       margin-bottom: 20px;
       cursor: pointer;
       border: none;
       border-radius: 5px;
       background-color: #007bff;
       color: #fff;
   
       &:hover {
         background-color: #0056b3;
       }
     }
   
     .post-heading {
       font-size: 2rem;
       margin-bottom: 20px;
     }
   
     .category-section {
       position: relative;
       margin-bottom: 20px;
   
       .category-input-container {
         display: flex;
         align-items: center;
   
         .category-input {
           flex: 1;
           padding: 10px;
           border-radius: 5px 0 0 5px;
           border: 1px solid #ccc;
           font-size: 1rem;
         }
   
         .dropdown-arrow {
           padding: 10px;
           border: 1px solid #ccc;
           border-left: none;
           border-radius: 0 5px 5px 0;
           background-color: #e9e9e9;
           cursor: pointer;
           font-size: 1rem;
           user-select: none;
   
           &:hover {
             background-color: #d4d4d4;
           }
         }
       }
   
       .category-dropdown {
         position: absolute;
         top: 100%;
         left: 0;
         width: 90%;
         max-height: 350px;
         overflow-y: auto;
         border: 1px solid #ccc;
         border-top: none;
         border-radius: 0 0 5px 5px;
         background-color: #fff;
         z-index: 100;
   
         li {
           padding: 10px;
           cursor: pointer;
   
           &:hover {
             background-color: #f0f0f0;
           }
         }
       }
     }
   
     .post-title {
       width: 98%;
       padding: 10px;
       margin-bottom: 20px;
       font-size: 1.5rem;
       border-radius: 5px;
       border: 1px solid #ccc;
     }
   
     .image-upload {
       margin-bottom: 20px;
   
       input[type='file'] {
         display: block;
         margin-bottom: 10px;
       }
   
       .image-preview {
         display: flex;
         gap: 10px;
         flex-wrap: wrap;
   
         .image-container {
           position: relative;
   
           img {
             max-width: 150px;
             border-radius: 5px;
             border: 1px solid #ccc;
           }
   
           button {
             position: absolute;
             top: 5px;
             right: 5px;
             background-color: rgba(0, 0, 0, 0.7);
             color: #fff;
             border: none;
             border-radius: 50%;
             width: 25px;
             height: 25px;
             cursor: pointer;
   
             &:hover {
               background-color: red;
             }
           }
         }
       }
     }
   
     .post-content {
      width: 100%;
      height: 800px; // Adjust this value as needed
      margin-bottom: 90px;
      font-family: 'Times New Roman', Times, serif;
      scrollbar-width: thin;  
      .ql-container {
        font-family: 'Times New Roman', Times, serif;
        font-size: 1.2rem;
        background-color: #f2f4f3;
      }
    }
   
     .action-buttons {
       display: flex;
       justify-content: flex-end;
       gap: 10px;
   
       button {
         padding: 10px 20px;
         font-size: 1rem;
         cursor: pointer;
         border: none;
         border-radius: 5px;

         &.delete-post {
          background-color: #ff3122;
          color: #ffffff;
          margin-right: auto;
   
           &:hover {
             background-color: #ff1100;
           }
         }
   
         &.save-draft {
           background-color: #ffc107;
           color: #000;
   
           &:hover {
             background-color: #e0a800;
           }
         }
   
         &.publish {
           background-color: #28a745;
           color: #fff;
   
           &:hover {
             background-color: #218838;
           }
         }
       }
     }
     

     .quill-section {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }
    
    .post-content {
      min-height: 200px; // Adjust this value as needed
    }
    
    .char-count {
      align-self: flex-end;
      font-size: 14px;
      color: #888;
      margin-top: 5px;
    }
    
    .popup {
      position: fixed;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #f44336;
      color: white;
      padding: 15px;
      border-radius: 5px;
      z-index: 1000;
    }
   }
   