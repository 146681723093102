@import "../../App.scss";

.body {
  background-color: rgb(42, 37, 37);
  padding-left: 2vw; 
  padding-top: 1vw; 
  color: white;
  width: 100%;
  display: flex;
  overflow-x: hidden;
  box-sizing: border-box;
  flex-wrap: wrap;
  left: 0;

  .head {
    display: flex;
    align-items: center;
    gap: 1vh;
    margin-bottom: 15px;

    h1 {
      font-size: 4vh;
      text-align: center;
      color: white;
      cursor: pointer;
    }

    img {
      margin-top: 1.9vh;
      width: 3vh;
      height: 3vh;
      cursor: pointer;
    }

    @include mobile {
      h1 {
            font-size: 3vh;
           }
      
          img {
            margin-top: 1.2vh;
            width: 2vh;
            height: 2vh;
          }

    }


  }
 
}

// Add these at the end of your file
@media (max-width: 1200px) {  
  * {
    box-sizing: border-box;
  }
}












/*
// if the above code makes any fatal error use this code

@import "../../App.scss";

.body {
     background-color: rgb(42, 37, 37);
     padding-left: 2vw; 
     padding-top: 1vw; 
     color: white;
     width: 100%;
     display: flex;
     overflow-x: hidden;
     box-sizing: border-box;
     flex-wrap: wrap; // add this

     @include mobile {

      }
     @include tablet-portrait {

     }
     @include tablet-landscape {

     }

     left: 0;
      .head{
           display: flex;
           align-items: center;
           gap: 1vh;

           h1{
                 font-size: 4vh;
                 text-align: center;
                 color: white;

           }
           img{
                 margin-top: 1.2vh;
                 width: 3vh;
                 height: 3vh;
           }
     }

}

*/