     // Screen Size methods
     
     @mixin mobile {
      @media (max-width: 768px) {
        @content;
      }
    }
    
    @mixin tablet-portrait {
      @media (min-width: 769px) and (max-width: 1024px) {
        @content;
      }
    }
    
    @mixin tablet-landscape {
      @media (min-width: 1025px) and (max-width: 1366px) {
        @content;
      }
    }
    
    //Extra
    @mixin ipad-pro-landscape {
      @media (min-width: 1281px) and (max-width: 1366px) {
        @content;
      }
    }

    @mixin ipad-pro {
      @media (min-width: 1023px) and (max-width: 1029px) {
        @content;
      }
    }
      
    @mixin ipad-mini {
      @media (min-width: 768px) and (max-width: 775px) {
        @content;
      }
    }
    @mixin ipad-air {
      @media (min-width: 820px) and (max-width: 1180px) {
        @content;
      }
    }
    @mixin  zfold{
      @media (min-width: 344px) and (max-width: 767px) {
        @content;
      }
    }

.brView {
     display: grid;
     grid-template-columns: 30vh  7fr; // reduced width of navbar
     grid-template-rows: 10vh 1fr; // reduced height of header
     height: 100vh;
     background-color: rgb(42, 37, 37);
     color: white;
   
     .header {
       grid-column: 1 / -1;
       grid-row: 1;
       background-color: #232323; // dark gray background
       color: #fff; // white text
       position: sticky;
       top: 0;
       z-index: 999;
       
     }
   
     .navBar {
       grid-column: 1 / 2;
       grid-row: 2 / -1;
       background-color: #212121; // dark gray background
       color: #fff; // white text
       padding: 0.5rem; // reduced padding
       font-size: 0.8rem; // reduced font size
       position: sticky;
       top: calc(15vh - 1rem);
   
       ul {
         list-style: none; // remove list styles
         padding: 0; // remove padding
         margin: 0; // remove margin
   
         li {
           padding: 0.2rem; // reduced padding
           border-bottom: 1px solid #555; // add a border
   
           &:last-child {
             border-bottom: none; // remove border from last item
           }
   
           a {
             color: #fff; // white text
             text-decoration: none; // remove text decoration
   
             &:hover {
               color: #ccc; // light gray text on hover
             }
           }
         }
       }
     }
   
     overflow-y: scroll;
     &::-webkit-scrollbar {
          display: none;
     }
     // overflow-x: scroll;
     // &::-webkit-scrollbar {
     //      display: none;
     // }
     .outlet {
       grid-column: 2 / -1;
       grid-row: 2 / -1;   
       overflow-x: hidden;
   
       &::-webkit-scrollbar {
         display: none;
       }
     }
  
      // Mobile layout
      @include mobile {
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr auto;

        .header {
          grid-column: 1 / -1;
          grid-row: 1 / 2;
          position: sticky;
          top: 0;
          z-index: 999;
          height: 4vh; // reduced height for mobile screen size
        }

        .navBar {
          grid-column: 1 / -1;
          grid-row: 3 / -1;
          background-color: #333; // dark gray background
          padding: 0.5rem; // reduced padding
          font-size: 0.8rem; // reduced font size
          position: sticky;
          bottom: 0;
          z-index: 500;

          ul {
            flex-direction: row;
            justify-content: space-between;

            li {
              padding: 0.2rem; // reduced padding
              border-bottom: none; // remove border

              a {
                color: #fff; // white text
                text-decoration: none; // remove text decoration

                &:hover {
                  color: #ccc; // light gray text on hover
                }
              }
            }
          }
        }

        .outlet {
          grid-column: 1 / -1;
          grid-row: 2 / 3;

          overflow-x: hidden;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }

      // Tablet layout (portrait)
      @include tablet-portrait {
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr auto;

        .header {
          grid-column: 1 / -1;
          grid-row: 1 / 2;
          position: sticky;
          top: 0;
          z-index: 999;
          height: 6vh;
        }

        .navBar {
          grid-column: 1 / -1;
          grid-row: 3 / -1;
          background-color: #333; // dark gray background
          padding: 0.5rem; // reduced padding
          font-size: 0.8rem; // reduced font size
          position: sticky;
          bottom: 0;
          z-index: 500;

          ul {
            flex-direction: row;
            justify-content: space-between;

            li {
              padding: 0.2rem; // reduced padding
              border-bottom: none; // remove border

              a {
                color: #fff; // white text
                text-decoration: none; // remove text decoration

                &:hover {
                  color: #ccc; // light gray text on hover
                }
              }
            }
          }
        }

        .outlet {
          grid-column: 1 / -1;
          grid-row: 2 / 3;

          overflow-x: hidden;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }

      // Tablet layout (landscape)
      @include tablet-landscape {
        grid-template-columns: 1fr 50fr;
        grid-template-rows: auto 1fr;

        .header {
          grid-column: 1 / -1;
          grid-row: 1 / 2;
          height: 6vh; // reduced height
        }

        .navBar {
          grid-column: 1 / 2;
          grid-row: 2 / -1;
          width: 6rem; // reduced width
          padding: 0; // reduced padding

        }

        .outlet {
          grid-column: 2 / -1;
          grid-row: 2 / -1;
        }
      }


}
 

// Break Point
 