.CL {
     background-color: rgb(92, 92, 92);
     padding: 1vw 2vw;
     color: white;
     width: 100%;
     display: flex;
     flex-direction: column;
     overflow-x: hidden;
     box-sizing: border-box;
     min-height: 100vh;

     h1{
          color: #FFA303;
     }
 }
 