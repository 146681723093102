@import "../../App.scss";
.CLSS {
  background-color: rgb(42, 37, 37);
  color: white;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  overflow-x: hidden;
  box-sizing: border-box;
  min-height: 100vh;

  .paginationn {
    display: flex;
    margin-top: 20px;
    margin-left: 35%;
    margin-bottom: 25px;
  
    .pagination-button {
      margin: 0 8px;
      padding: 8px 16px;
      border: 2px solid #ccc;
      background-color: #f9f9f9;
      color: #333;
      font-size: 14px;
      font-weight: 500;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
  
      &:hover {
        background-color: #e0e0e0;
        border-color: #999;
      }
  
      &.active {
        background-color: #007bff;
        color: #fff;
        border-color: #007bff;
      }
  
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
  
}

@include mobile {
  .CLSS {
    padding: 2vw;

    .paginationn {
      margin-left: 0px;
      .pagination-button {
        padding: 4px 8px;
      }
  }
}
}
@include ipad-pro {
  .CLSS {
    justify-content: space-around;
  }
}
@include ipad-mini {
  .CLSS {
    justify-content: space-around;
  }
}
@include tablet-portrait  {
  .CLSS {
    justify-content: space-around;
  }
}
@include ipad-air {
  .CLSS {
    justify-content: space-around;
  }
}