.comments-container {
     font-family: 'Arial', sans-serif;
     background-color: #2e2e2e; // Dark, modern background
     padding: 20px;
     border-radius: 10px;
     max-width: 700px;
     margin: 0 auto;
     color: #e0e0e0;
     box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
     position: relative; // For positioning the input box
     padding-bottom: 80px; // Increased bottom padding to ensure visibility of comments
 }
 
 .comments-list {
     max-height: calc(60vh - 50px); // Adjusted to accommodate the input box
     overflow-y: auto;
     padding-right: 15px;
     scrollbar-width: thin;
     scrollbar-color: #555 #2e2e2e;
 }
 
 .comments-list::-webkit-scrollbar {
     width: 8px;
 }
 
 .comments-list::-webkit-scrollbar-thumb {
     background: #555;
     border-radius: 4px;
 }
 
 .comment {
     position: relative;
     border-bottom: 1px solid #444;
     padding: 15px;
     background-color: #3c3c3c;
     border-radius: 8px;
     margin-bottom: 10px;
     transition: background-color 0.3s, transform 0.3s;
 }
 
 .user-link {
     display: flex;
     align-items: center;
     text-decoration: none;
     color: #e0e0e0;
     margin-bottom: 5px;
     transition: color 0.3s;
     &:hover {
         color: #87cefa; // Light blue hover effect
     }
 }
 
 .user-avatar {
     width: 36px;
     height: 36px;
     border-radius: 50%;
     margin-right: 10px;
     border: 2px solid #666; // Subtle border
     transition: border-color 0.3s, transform 0.3s;
     object-fit: cover;

     &:hover {
         border-color: #87cefa; // Light blue border on hover
         transform: scale(1.1);
     }
 }
 
 .user-name {
     font-weight: bold;
     font-size: 1.1em;
 }
 
 .comment-content {
     margin: 5px 0;
     font-size: 1em;
     line-height: 1.5;
     color: #dcdcdc; // Slightly lighter text for contrast
 }
 
 .comment-time {
     font-size: 0.85em;
     color: #a0a0a0;
 }
 
 .comment-menu {
     position: absolute;
     top: 20px;
     right: 10px;
     cursor: pointer;
 }
 
 .menu-icon {
     width: 15px;
     height: 15px;
     filter: brightness(0) invert(1); // White icon
     transition: opacity 0.2s, transform 0.2s;
     &:hover {
         opacity: 0.8;
         transform: rotate(180deg); // Rotate on hover
     }
 }
 
 .dropdown-menu {
     position: absolute;
     top: 30px;
     right: 10px;
     background: #3c3c3c;
     padding: 10px;
     border-radius: 8px;
     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
     z-index: 100;
     min-width: 120px;
     opacity: 0;
     visibility: hidden;
     transition: opacity 0.2s, visibility 0.2s;
 }
 
 .comment:hover .dropdown-menu {
     opacity: 1;
     visibility: visible;
 }
 
 .dropdown-menu button {
     display: block;
     width: 100%;
     padding: 8px;
     background: none;
     border: none;
     color: #dcdcdc;
     text-align: left;
     cursor: pointer;
     font-size: 0.9em;
     border-radius: 4px;
     transition: background-color 0.3s;
 }
 
 .dropdown-menu button:hover {
     background-color: #444;
 }
 
 .comment-input-container {
     position: absolute;
     bottom: 10px;
     left: 10px;
     right: 10px;
     background: #3c3c3c;
     
     z-index: 500;
     border-radius: 8px;
     display: flex;
     align-items: center;
     box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.3);
 }
 
 .comment-input {
     flex: 1;
     background: #2e2e2e;
     border: 1px solid #555;
     border-radius: 5px;
     padding: 6px;
     color: #e0e0e0;
     font-size: 0.8em;
     line-height: 1.4;
     resize: none; // Prevent resizing
     outline: none;
     transition: border-color 0.3s;
     font-family: 'Arial', sans-serif;
     &:focus {
         border-color: #87cefa; // Light blue border on focus
     }
 }
 
 .edit-comment-form {
    display: flex;
    flex-direction: column;
    background-color: #2e2e2e;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  
    textarea {
      background-color: #23272a;
      color: #ffffff;
      border: none;
      padding: 0.8rem;
      border-radius: 6px;
      resize: none;
      height: 50px;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      margin-bottom: 0.8rem;
      box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);
      transition: background-color 0.3s ease;
  
      &:focus {
        background-color: #40444b;
        outline: none;
      }
  
      &::placeholder {
        color: #b9bbbe;
      }
    }
  
    .edit-cmtbuttons {
      display: flex;
      gap: 0.5rem;
  
      .save-editbtn, .cancel-editbtn {
        padding: 0.6rem 1.2rem;
        font-size: 14px;
        font-family: 'Roboto', sans-serif;
        border: none;
        border-radius: 6px;
        cursor: pointer;
        transition: background-color 0.3s ease;
      }
  
      .save-editbtn {
        background-color: #7289da;
        color: #ffffff;
  
        &:hover {
          background-color: #677bc4;
        }
  
        &:disabled {
          background-color: #4e5d94;
          cursor: not-allowed;
        }
      }
  
      .cancel-editbtn {
        background-color: #f04747;
        color: #ffffff;
  
        &:hover {
          background-color: #d03c3c;
        }
      }
    }
  }
  
 .comment-submit {
     background: #87cefa; // Light blue for submit button
     border: none;
     color: #fff;
     padding: 6px 10px;
     margin-left: 8px;
     margin-right: 8px;
     border-radius: 5px;
     cursor: pointer;
     font-size: 0.8em;
     transition: background-color 0.3s;
     &:hover {
         background-color: #5b8db8; // Darker blue on hover
     }
 }
 
 @media (max-width: 768px) {
     .comments-container {
         padding: 15px;
         max-width: 100%;
         padding-bottom: 60px; // Adjusted for smaller screens
     }
 
     .user-name {
         font-weight: bold;
         font-size: 0.9em;
     }
 
     .comment-content {
         margin: 5px 0;
         font-size: 0.95em;
     }
 
     .user-avatar {
         width: 32px;
         height: 32px;
     }
 }
 