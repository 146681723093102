@import "../../App.scss";


.post {
     padding: 20px;
        
     .postElement {
       padding: 0.5vh;
       background-color: rgb(30, 30, 30);
       border-radius: 1vh;
       width: 50vh;
       height: 50vh;
       position: relative;
       transition: transform 0.3s ease-in-out; // add transition for hover effect
       cursor: pointer;
 
     
   
       &:hover {
         transform: scale(1.02); // scale up on hover
         box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); // add box shadow on hover
       }
   
       .wrap {
         display: flex;
         flex-direction: column;
         position: relative;
   
         .postImg {
           width: 100%;
           height: 25vh;
 
         }
   
         .profDetails {
           background-color: rgba(0, 0, 0, 0.552);
           position: absolute;
           bottom: 0;
           left: 0;
           display: flex;
           margin-top: 1vh;
           gap: 1.7vh;
           align-items: center;
           padding-left: 1vh;
           flex-grow: 20;
           width: inherit;
           padding-right: 3vh;
           padding-top: 0.5vh;
           padding-bottom: 0.1vh;
           border-radius: 0 5vh 5vh 0;

   
           .profPic {
             width: 5.5vh;
             height: 5.5vh;
             border-radius: 50%;
             object-fit: cover;

           }
   
           .userName {
             font-size: 1.5vh;
           }
   
           .time {
             font-size: 1vh;
           }
         }
       }

       .textContent{

        display: flex;
        flex-direction: column;
        max-height: calc(50vh - 30vh); // Restrict height based on footer space
        overflow: hidden; // Prevent overflow
        justify-content: flex-start; // Align text at the top
        
         
        .postTitle {
          font-size: 2.5vh;
          margin-bottom: 0;
          color: white !important;
       }
   
       .postContent {
        font-size: 1.8vh;
        overflow-y: auto; // Allow scrolling if content exceeds the limit
      
        /* Scrollbar styles */
        &::-webkit-scrollbar {
          width: 2px; /* width of the scrollbar */
          height: 12px; /* height of the scrollbar */
        }
      
        &::-webkit-scrollbar-track {
          background: #f1f1f1; /* scrollbar track background color */
          border-radius: 10px; /* rounded corners */
        }
      
        &::-webkit-scrollbar-thumb {
          background: #888; /* scrollbar thumb background color */
          border-radius: 10px; /* rounded corners */
        }
      
        &::-webkit-scrollbar-thumb:hover {
          background: #555; /* scrollbar thumb hover background color */
        }
      }
    }
   
       
   
    .postFooter {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 0.5vh;
      padding: 1vh 2vh;
      width: 90%;   // Ensures it spans the full width of the container
      justify-content: space-between;  // Distribute space between left and right sections
      gap: 1rem;
    
      .categoryNameFooter {
        padding: 0.5vh 1vh;
        background-color: rgb(92, 92, 92);
        border-radius: 5px;  // Rounded corners
        color: #fff;  // White text for contrast
        font-size: 2vh;
        font-weight: bold;
        white-space: wrap;  // Prevent text wrapping
        flex-shrink: 3;  // Don't allow it to shrink
        cursor: pointer;
        color: white
      }
    
      .footerRight {
        display: flex;
        align-items: center;
        gap: 2vh;  // Space between the right side elements
    
        .viewsDiv {
          display: flex;
          align-items: center;
          cursor: default;
    
          .viewsIcon {
            width: 2.5vh;
            height: 2.5vh;
            margin-right: 3px;
          }
    
          .viewsCount {
            font-size: 1.5vh;
          }
        }
    
        .share {
          width: 2vh;
          height: 2.5vh;
          transition: transform 0.3s ease-in-out;
    
          &:hover {
            transform: scale(1.2);
          }
        }
    
        .save {
          width: 2.5vh;
          height: 2.7vh;
          transition: transform 0.3s ease-in-out;
    
          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
    
    
    
  }



   }

 

       @include mobile {
      .post {
        padding: 1.2vh;
        padding-right:  1vh;
        
        
           
        .postElement {
          
          width: 40vh;
          height: 40vh;
  
          .wrap {
            .postImg {
              width: 100%;
              height: 20vh;
    
            }
      
            .profDetails {
               
              .profPic {
                width: 4.5vh;
                height: 4.5vh;
                border-radius: 50%;
              }
      
              .userName {
                font-size: 1.5vh;
              }
      
              .time {
                font-size: 1vh;
              }
            }
          }
   
          .textContent{
    
            display: flex;
          flex-direction: column;
          max-height: calc(50vh - 35vh); // Restrict height based on footer space
          overflow: hidden; // Prevent overflow
          justify-content: flex-start; // Align text at the top
        
           .postTitle {
            font-size: 1.6vh;
            margin-bottom: 0;
            font-weight: 900;
           }
      
          .postContent {
            font-size: 1.5vh;
            overflow-y: auto; // Allow scrolling if content exceeds the limit
      
          }
          }
      
          
      
          .postFooter {
            gap: 3vh;
      
            .viewsDiv {
              
              gap: 0.5vh;
      
              .viewsIcon {
                width: 3.5vh;
                height: 3.5vh;
              }
      
              .viewsCount {
               font-size: 1.5vh;
              }
            }
      
            .share {
              align-items: center;
              width: 2vh;
              height: 2.5vh;
   
              &:hover {
               transform: none; // scale up on hover
             }
            }
      
            .save {
              width: 2.5vh;
              height: 2.7vh;
              margin-left: auto;
              transition: transform 0.3s ease-in-out; // add transition for hover effect
      
              &:hover {
                transform: none; // scale up on hover
              }
            }
          }
        }
   
   
        
      }
     }