$user-info-bg: #1d1d1d;
$input-bg: #2c2c2c;
$input-border: #444;
$text-color: #fff;
$highlight-color: #ff6d00;
$btn-bg: #ff6d00;
$btn-text: #fff;
$input-hover-bg: #383838;
$box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
$hover-highlight-color: lighten($highlight-color, 10%);

.user-info-container {
     display: flex;
     justify-content: center;
     align-items: center;
     min-height: 100vh;  // Set to 100vh to cover full screen
     background-color: $user-info-bg;
   }
   

.user-info-box {
  background-color: $input-bg;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: $box-shadow;
  width: 350px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
  }
}

.title {
  color: $highlight-color;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
}

.subtitle {
  color: $text-color;
  margin-bottom: 2rem;
  font-size: 1rem;
  line-height: 1.5;
}

.user-info-form {
  display: flex;
  flex-direction: column;
}

.profile-pic-container {
     margin-bottom: 1.5rem;
     text-align: left;
   
     label {
       color: $text-color;
       font-size: 1rem;
       margin-bottom: 0.5rem;
       display: block;
     }
   
     input[type="file"] {
       display: block;
       margin-top: 0.5rem;
     }
   
     .profile-pic-preview {
       margin-top: 1rem;
   
       img {
         width: 100px;
         height: 100px;
         border-radius: 50%;
         object-fit: cover;
         box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
       }
     }
   }
   

.input-group {
  margin-bottom: 1.5rem;
  text-align: left;

  label {
    color: $text-color;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    display: block;
  }

  input {
    background-color: $input-bg;
    color: $text-color;
    border: 1px solid $input-border;
    border-radius: 5px;
    padding: 0.75rem;
    width: 100%;
    box-sizing: border-box;
    transition: background-color 0.3s ease, border-color 0.3s ease;

    &:hover {
      background-color: $input-hover-bg;
    }

    &:focus {
      outline: none;
      border-color: $highlight-color;
      background-color: $input-hover-bg;
    }
  }
}

.error-message {
     color: red;
     font-size: 0.875rem;
     margin-top: 0.5rem;
   }
   

.interests-tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;

  .tag {
    background-color: $highlight-color;
    color: $btn-text;
    padding: 6px 10px;
    border-radius: 15px;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    display: inline-flex;
    align-items: center;

    .remove-tag {
      margin-left: 0.5rem;
      cursor: pointer;
      font-size: 0.8rem;
      color: $text-color;

      &:hover {
        color: $btn-text;
      }
    }
  }
}

.submit-btn {
  background-color: $btn-bg;
  color: $btn-text;
  border: none;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: darken($btn-bg, 10%);
    transform: translateY(-3px);
  }
}

.success-message {
  color: green;
  font-size: 0.8em;
  margin-top: 5px;
}

.error-message {
  color: red;
  font-size: 0.8em;
  margin-top: 5px;
}