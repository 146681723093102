.about-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #f0f0f0; // Light background for the whole section
    min-height: 100vh; // Full page height
  }
  
  .about-content {
    max-width: 800px; // Limit the width to avoid full-screen text
    width: 100%; // Ensure it takes full available width up to 800px
    background-color: white; // Text box background
    padding: 40px; // Add padding inside the box
    border-radius: 10px; // Rounded corners
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    color: #333;
  
    .about-heading {
      color: #FE7F2D;
      text-align: center;
      font-size: 28px;
      margin-bottom: 20px;
    }
  
    .about-subheading {
      color: #FE7F2D;
      font-size: 22px;
      margin-top: 40px;
    }
  
    .about-thankyou {
      text-align: center;
      margin-top: 30px;
      font-weight: bold;
    }
  
    p {
      line-height: 1.8;
      margin-bottom: 20px;
      text-align: justify; // Improve text readability
    }
  }
  