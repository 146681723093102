@import "../../App.scss";

.searchContainer {
  backdrop-filter: blur(10px);
  padding: 30px;
  color: #fff;
  max-width: 100vw; // Add this line
  margin: 0 auto; // Add this line to center the container
  display: flex;
  flex-direction: column;
  align-items: center;
}

.searchWrap {
  max-width: 600px;
  width: 100%;
  padding: 25px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37);
  backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  animation: glow 2s infinite alternate;

  .searchBox {
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    width: 100%;
    margin-bottom: 30px;
    transition: transform 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;

    @media (min-width: 1024px) {
      &:hover {
        transform: scale(1.02);
      }
    }

    input[type="text"] {
      flex: 1;
      padding: 15px;
      font-size: 18px;
      border: none;
      border-radius: 15px;
      background: transparent;
      color: #fff;
      box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.2);
      transition: box-shadow 0.3s ease, transform 0.3s ease;


    }

    button.searchButton {
      padding: 15px 30px;
      font-size: 18px;
      border: none;
      border-radius: 15px;
      background-color: #FF6D00;
      color: #fff;
      cursor: pointer;
      margin-left: 15px;
      transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;

      @media (max-width: 1023px) {
        display: none;
      }

      @media (min-width: 1024px) {
        &:hover {
          background-color: #FF6D00;
          transform: scale(1.1);
          box-shadow: 0 0 15px #ff6f00cd;
        }

        &:active {
          transform: scale(0.98);
        }
      }
    }
  }

  .searchHistory {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    

    .searchHistoryItem {
      padding: 12px 20px;
      border-radius: 20px;
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(8px);
      color: #fff;
      display: flex;
      align-items: center;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
      transition: transform 0.3s ease, background 0.3s ease;
      border: 1px solid rgba(255, 255, 255, 0.2);
      word-break: break-word;

      @media (min-width: 1024px) {
        &:hover {
          background-color: #ff6f00a7;
          transform: translateY(-5px);
          box-shadow: 0 6px 20px #FF6D00;
        }
      }

      button.clearButton {
        font-size: 12px;
        border: none;
        border-radius: 50%;
        background-color: #ffffff00;
        color: #fff;
        cursor: pointer;
        margin-left: 19px;
        margin-right: 0;
        transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;

        @media (min-width: 1024px) {
          &:hover {
            background-color: #ff1a1a;
            transform: scale(1.1);
            box-shadow: 0 0 15px rgba(255, 0, 127, 0.8);
          }

          &:active {
            transform: scale(0.98);
          }
        }
      }
    }
  }
  .userLink {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Inherit color from parent */
  
    .userItem {
      display: flex;
      align-items: center;
      margin: 20px 0;
  
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;
        object-fit: cover;
      }
  
      span {
        font-size: 18px;
        color: #e3e3e3;
      }
    }
  }
  
}

@keyframes glow {
  0% {
    box-shadow: 0 0 10px #ff6f007d;
  }
  100% {
    box-shadow: 0 0 25px #FF6D00;
  }
}

@include mobile {
  .searchWrap {
    padding: 15px;
    
    .searchBox {
      input[type="text"] {
        width: 100%;
      }
    }
  }
}

@include tablet-landscape {
  .searchWrap {
    padding: 20px;
    
    .searchBox {
      input[type="text"] {
        font-size: 16px;
      }
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  button {
    background-color: #2d2d2d;
    color: #fff;
    border: none;
    padding: 10px 15px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: #4a90e2;
      transform: translateY(-2px);
    }

    &:disabled {
      background-color: #555;
      cursor: not-allowed;
      opacity: 0.7;
    }

    &.active {
      background-color: #4a90e2;
      font-weight: bold;
      pointer-events: none;
    }
  }

  /* Style for Previous and Next buttons */
  button:first-child,
  button:last-child {
    background-color: #444;
    padding: 10px 20px;

    &:disabled {
      background-color: #666; /* Darker shade for disabled state */
    }
  }
}
