@import "../../App.scss";

.register-container {
  display: flex;
  height: 100vh;
  background-image: url('/icons/bc1.png');
  background-size: cover;
  animation: imageAnimation 50s linear infinite;

  .left-side {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.6); // semi-transparent dark background

    h2 {
      font-size: 32px;
      margin-bottom: 20px;
      font-weight: 700;
    }

    p {
      font-size: 18px;
      line-height: 1.6;
      font-weight: 400;
    }
  }

  .right-side {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;

    @include mobile {
      padding: 12px;
    }

    .card {
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      padding: 40px;
      width: 400px;
      text-align: center;

      .logo {
        max-width: 150px;
        margin-bottom: 20px;
      }

      .form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        input {
          padding: 10px;
          border: 1px solid #ddd;
          border-radius: 5px;
          font-size: 16px;
          width: 100%;
        }

        .show-password{
          cursor: pointer;
        }

        .register-btn {
          background-color: #4CAF50;
          color: #fff;
          padding: 12px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-size: 16px;
          width: 100%;
        }

        .register-btn:hover {
          background-color: #3e8e41;
        }
      }

      .divider {
        margin: 20px 0;
        font-size: 16px;
        color: #666;
      }

      .login-btn {
        background-color: #FE7F2D;
        color: #fff;
        padding: 12px 35px; // Combined padding-left and padding-right for conciseness
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 20px;
        font-size: 16px;
      }

      .login-btn:hover {
        background-color: #f7f7f7;
        color: #FE7F2D;
      }
    }
  }

  @include mobile {
    flex-direction: column;

    .left-side {
      display: none;  // Hide the left side on mobile
    }
  }

  @include tablet-portrait {
    flex-direction: column;

    .left-side {
      display: none;  // Hide the left side on tablet portrait
    }
  }

  @include tablet-landscape {
    flex-direction: row;

    .right-side {
      display: flex;  // Ensure the right side is visible in landscape
    }
  }
}
