@import "../../App.scss";

.exploreContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  .surfSpace {
    margin-bottom: 10px;
    width: 100%;
    position: relative;

    .searchBox {
      display: flex;
      align-items: center;
      padding: 12px;
      border: 1px solid #444;
      border-radius: 5px;
      background-color: #222;
      width: 60%;
      margin: 0 auto;

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        filter: invert(1);
      }

      input {
        width: 100%;
        padding: 10px;
        background-color: #333;
        color: #fff;
        border: none;
        border-radius: 5px;
        font-size: 16px;
      }
    }

    // Search Results List
    .searchResults {
      position: absolute;
      top: 100%;
      left: 20%;
      right: 20%;
      background-color: #1a1a1a;
      border: 1px solid #444;
      border-top: none;
      border-radius: 0 0 5px 5px;
      max-height: 300px;
      overflow-y: hidden;
      overflow-x: hidden;
      z-index: 10;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.4);

      .searchResult {
        display: block;
        padding: 12px;
        text-decoration: none;
        color: white;
        font-weight: bold;
        font-size: 18px;
        transition: background-color 0.2s, transform 0.2s;
        border-bottom: 1px solid #333;

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          background-color: #2a2a2a;
          transform: scale(1.02);
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        }

        &.category {
          color: #ff8a00;
        }

        &.post {
          color: #00d4ff;
        }
      }
    }

    // After search box divider
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: #444;
      margin-top: 20px;
    }
  }

  .trendSpace {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 600px;

    .trendBox {
      flex-grow: 2;
      margin: 10px;
      text-align: center;
      background: linear-gradient(135deg, #ff8a00 0%, #d44500 50%, #66100b 100%);
      border-radius: 15px;
      box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
      transition: transform 0.3s ease, box-shadow 0.3s ease;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
      }

      img {
        width: 100%;
        height: 150px;
        object-fit: cover;
        border-radius: 15px 15px 0 0;
      }

      p {
        font-size: 16px;
        margin-top: 10px;
        color: #ffffff;
        padding: 0 10px;
      }
    }
  }

  @include mobile {
    .surfSpace {
      margin-bottom: 10px;
      width: 100%;

      .searchBox {
        width: 90%;
      }

      .searchResults {
        left: 5%;
        right: 5%;
      }
    }

    .trendSpace {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 100%;
      align-items: center;
      justify-content: center;

      .trendBox {
        max-width: 100%;
        text-align: center;

        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
          border-radius: 10px;
        }

        p {
          font-size: 16px;
          margin-top: 10px;
        }
      }
    }
  }
}
// Break point