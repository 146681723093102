@keyframes imageAnimation {
  0%, 100% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 50% 50%;
  }
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('/icons/bc1.png'); 
  background-size: cover;
  animation: imageAnimation 50s linear infinite;

  .card {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    padding: 40px;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
      max-width: 150px;
      margin-bottom: 20px;
    }

    h1 {
      font-size: 28px;
      margin-bottom: 30px;
      font-weight: 600;
    }

    .form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      width: 100%;

      input {
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 100%;
        font-size: 16px;
      }

      input:focus {
        border-color: #4CAF50;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      }

      .show-password{
        cursor: pointer;
      }

      .login-btn {
        background-color: #4CAF50;
        color: #fff;
        padding: 12px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        width: 100%;
        font-size: 16px;
      }

      .login-btn:hover {
        background-color: #3e8e41;
      }

      .forgot-psw {
        margin-top: 10px;
        text-align: center;
        color: #337ab7;
        cursor: pointer;
      }

      .forgot-psw:hover {
        color: #23527c;
      }
    }

    .divider {
      margin: 20px 0;
      font-size: 16px;
      color: #666;
    }

    .register-btn {
      background-color: #FE7F2D;
      color: #fff;
      padding: 12px;
      padding-left: 35px;
      padding-right: 35px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      margin-top: 10px;
      font-size: 16px;
      width: 100%;
      box-sizing: border-box;
    }

    .register-btn:hover {
      color: #FE7F2D;
      background-color: #f7f7f7;
    }
  }
}
