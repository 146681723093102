@import "../../App.scss";

.postDP {
  padding: 0;
  flex: 1 1 300px;
  max-width: 50vh;
  min-width: 350px;
  margin-bottom: 2vw;
  text-decoration: none;

  @include ipad-pro {
    max-width: 40vh;
    min-width: 600px;
  }
  
  @include ipad-mini {
    max-width: 40vh;
    min-width: 350px;        
  }

  @include tablet-portrait {
    max-width: 40vh;
    min-width: 600px; 
  }

  @include ipad-air {
    max-width: 40vh;
    min-width: 600px;   
  }

  .postElementDP {
    padding: 0.5vh;
    background-color: rgb(30, 30, 30);
    border-radius: 1vh;
    width: 100%;
    height: 50vh;
    position: relative;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    overflow: hidden; // Add this to prevent content overflow
 
    &:hover {
      transform: scale(1.02);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }

    .wrapDP {
      display: flex;
      flex-direction: column;
      position: relative;
      flex-shrink: 0; // Prevent image section from shrinking

      .postImgDP {
        width: 100%;
        height: 25vh;
        object-fit: cover; // Ensure image covers area properly
      }

      .profDetailsDP {
        background-color: rgba(0, 0, 0, 0.552);
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        margin-top: 1vh;
        gap: 1.7vh;
        align-items: center;
        padding: 0.5vh 3vh 0.1vh 1vh;
        flex-grow: 20;
        width: inherit;
        border-radius: 0 5vh 5vh 0;

        .profPicDP {
          width: 5.5vh;
          height: 5.5vh;
          border-radius: 50%;
          object-fit: cover;
        }

        .userNameDP {
          font-size: 1.5vh;
        }

        .timeDP {
          font-size: 1vh;
        }
      }
    }

    .textContentDP {
      display: flex;
      flex-direction: column;
      padding: 1vh;
      flex: 1;
      overflow-y: auto; // Enable scrolling for overflow content
      
      // Hide scrollbar but keep functionality
      &::-webkit-scrollbar {
        width: 0;
        background: transparent;
      }

      .postTitleDP {
        font-size: 2.5vh;
        margin: 1vh 0;
        color: white !important;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; // Limit to 2 lines
        -webkit-box-orient: vertical;
      }

      .postContentDP {
        font-size: 1.8vh;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6; // Limit to 6 lines
        -webkit-box-orient: vertical;
        margin: 0;
        line-height: 1.4;
      }

      @include ipad-air {
        .postTitleCP {
          font-size: 2vh;
        }

        .postContentCP {
          font-size: 1.7vh;
        }
      }
    }
  }

  @include mobile {
    padding: 1.2vh 1vh 1.2vh 1.2vh;
    
    .postElementDP {
      width: 40vh;
      height: 40vh;

      .wrapDP {
        .postImgDP {
          height: 20vh;
        }
  
        .profDetailsDP {
          .profPicDP {
            width: 4.5vh;
            height: 4.5vh;
          }
  
          .userNameDP {
            font-size: 1.5vh;
          }
  
          .timeDP {
            font-size: 1vh;
          }
        }
      }

      .textContentDP {
        .postTitleDP {
          font-size: 1.6vh;
          font-weight: 900;
          -webkit-line-clamp: 1; // Limit to 1 line on mobile
        }
  
        .postContentDP {
          font-size: 1.5vh;
          -webkit-line-clamp: 4; // Limit to 4 lines on mobile
        }
      }
    }
  }
}