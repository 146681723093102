// Global Reset for margins and padding on body and html


// Wrapper and header styling
.CLOL {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh; // Ensures full height
  background-color: rgb(92, 92, 92);
  color: white;
  
  h1 {
    margin: 20px 0;
    color: #FFA303;
    font-size: 2.4rem;
    font-weight: 600;
  }

  .loading-message {
    font-size: 1.2rem;
    color: #666;
    margin: 10px 0;
  }

  .error-message {
    color: #e74c3c;
    font-size: 1.2rem;
    font-weight: bold;
    margin: 20px 0;
  }
}

// Header styling
.olHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  background-color: #2d2d2d;
  color: #f5f5f5;
  margin-bottom: 10px;
  z-index: 500;

  .logo {
    height: 40px;
    padding-left: 15px;
    cursor: pointer;
  }

  .olmenu-icon {
    display: none;
    font-size: 2rem;
    color: #f5f5f5;
    background: none;
    border: none;
    padding-right: 25px;
    cursor: pointer;
  }

  .olnav-links {
    display: flex;
    gap: 20px;
    padding-right: 20px;

    .olAbout, .olSignup {
      font-size: 1.1rem;
      cursor: pointer;
      color: #f5f5f5;
      transition: color 0.2s ease;
      padding: 8px 16px;

      &:hover {
        color: #ddd;
      }
    }

    .olLogin {
      background-color: #ed9716;
      padding: 8px 16px;
      border-radius: 5px;
      font-size: 1.1rem;
      cursor: pointer;
      color: #000000;
      transition: background-color 0.2s ease;
      text-decoration: none;

      &:hover {
        background-color: #e98a00;
      }
    }
  }
}

// Pagination styling
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .pagination-button {
    background-color: #2d2d2d;
    color: #f5f5f5;
    padding: 8px 12px;
    margin: 0 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &.active {
      background-color: #f5f5f5;
      color: #2d2d2d;
    }

    &:hover:not(.active) {
      background-color: #444;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .pagination-ellipsis {
    padding: 8px 12px;
    color: #888;
  }
}

// Mobile and tablet styles
@media (max-width: 768px) {
  .olHeader {
    .olnav-links {
      display: none;
      position: absolute;
      right: 20px;
      top: 60px;
      flex-direction: column;
      gap: 30px;
      background-color: #333;
      padding: 25px;
      border-radius: 8px;

      .olLogin {
        margin-left: 5px;
      }

      &.open {
        display: flex;
      }
    }

    .olmenu-icon {
      display: block;
    }
  }
}


.login-prompt-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .login-prompt-modal {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    max-width: 400px;
    width: 90%;
    text-align: center;
  
    h3 {
      margin-top: 0;
      font-size: 1.5rem;
      color: #333;
    }
  
    p {
      color: #666;
      margin-bottom: 1.5rem;
    }
  }
  
  .login-prompt-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.8rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s;
  
    &:hover {
      background-color: #0056b3;
    }
  }
  
  .close-prompt-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    color: #666;
    padding: 5px;
  
    &:hover {
      color: #333;
    }
  }