@import "../../App.scss";

.navBar {
     
   
     max-width: 100%;
  
     .container {
       height: auto;
       display: flex;
       flex-direction: column;
       gap: 7vh;
       margin-top: 5vh;
 


     
       a {
         text-decoration: none;
         color: #ffffff;
         transition: color 0.2s ease, transform 0.2s ease; // added transform transition
         font-size: 18px; // increased font size
         display: flex; // added to center align the text with the icon
         align-items: center; // added to center align the text with the icon
   
         &:hover {
           color: #ff9b58; // change hover color to a more vibrant orange
           transform: scale(1.05); // added scale effect on hover
           animation: pulse 0.5s ease-in-out; // added pulse effect
         }
       }
   
       img {
         width: 32px; // increased icon size
         height: 32px; // increased icon size
         margin-right: 15px; // increased margin to accommodate larger icon
       }
   
       .profileIcon {
        border-radius: 50%;
      }

       .home,
       .saved,
       .explore,
       .profile,
       .createPost {
         display: flex;
         align-items: center;
         justify-content: center;
         padding: 15px;
         transition: background-color 0.3s ease, transform 0.3s ease;
         border-radius: 10px; // consistent border radius for all elements
       
         &:hover {
           background-color: rgba(0, 0, 0, 0.05); // subtle background color change
           transform: translateY(-3px); // slight lift on hover
         }
       }

       @include mobile {
        .saved img {
          width: 25px; // change the saved image size
          height: 25px; // change the saved image size
        }
         
       }
       .createPost {
         margin-top: 2.5vh;
         background-color: #6f6f6f; // consistent background color
         border-radius: 10px;
         box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); // subtle shadow effect
         
      
       
         img {
           width: 25px;
           height: 25px;
         }
       
         &:hover {
           background-color: #555; // slightly darker background color on hover
           box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); // enhanced shadow effect on hover
           transform: translateY(-5px); // slightly more lift on hover
         }
       }
      }
   
   
   
     // Media query for smaller screens
     @media (max-width: 768px) {
       width: 100vw;
       height: auto;
       position: fixed;
       bottom: 0;
       top: auto;
       left: 0;
       right: 0;
       padding: 10px 0;
       display: flex;
       justify-content: center;
   
       .container {
         flex-direction: row;
         justify-content: space-around;
         width: 100%;
         margin-top: 0;
         gap: 0;
         padding: 0;
   
   
         .home {
           order: 1;
         }
   
         .explore {
           order: 2;
         }
   
         .createPost {
           order: 3;
         }
   
         .saved {
           order: 4;
         }
   
         .profile {
           order: 5;
         }
   
         a {
           font-size: 0;
           img {
             margin-right: 0;
           }
         }
   
   
         .home,
       .saved,
       .explore,
       .profile,
       .createPost {
      
         &:hover {
           border-radius: 10px;
           background-color: rgba(0, 0, 0, 0.1); // added subtle background color change on hover
           transform: translateY(-0.1px); // added slide-in effect on hover
         }
       }
   
         .createPost {
           margin: 0;
           padding: 10px;
           background-color: transparent;
           border-radius: 0;
           box-shadow: none;
           margin-left: 0;
       
           img {
             width: 30px;
             height: 30px;
           }
       
           &:hover {
             background-color: transparent;
             box-shadow: none;
             border-bottom: none;
           }
         }
   
       }
     }



     @include tablet-landscape {
          .navBar {
            max-width: 100%; /* Add this line */
             

            .container {
              a {
                font-size: 0; // remove text and leave only icons
                img {
                  margin-right: 0;
                }
              }
          
              .home,
              .saved,
              .explore,
              .profile,
              .createPost {
                &:hover {
                  background-color: transparent;
                  transform: none;
                  border-radius: 0;
                  box-shadow: none;
                  border-bottom: none;
                }
              }
  
              .createPost {
                 margin: 0;
                  background-color: transparent;
                 border-radius: 0;
                 box-shadow: none;
                }
            }

          }
        }
      
        @include tablet-portrait {
          width: 100vw;
          height: auto;
          position: fixed;
          bottom: 0;
          top: auto;
          left: 0;
          right: 0;
          padding: 10px 0;
          display: flex;
          justify-content: center;
      
          .container {
            flex-direction: row;
            justify-content: space-around;
            width: 100%;
            margin-top: 0;
            gap: 0;
            padding: 0;
      
            .home {
              order: 1;
            }
      
            .explore {
              order: 2;
            }
      
            .createPost {
              order: 3;
            }
      
            .saved {
              order: 4;
            }
      
            .profile {
              order: 5;
            }
      
            a {
              font-size: 0;
              img {
                margin-right: 0;
              }
            }
      
            .home,
            .saved,
            .explore,
            .profile,
            .createPost {
              &:hover {
                border-radius: 10px;
                background-color: rgba(0, 0, 0, 0.1); // added subtle background color change on hover
                transform: translateY(-0.1px); // added slide-in effect on hover
              }
            }
      
            .createPost {
              margin: 0;
              padding: 10px;
              background-color: transparent;
              border-radius: 0;
              box-shadow: none;
              margin-left: 0;
      
              img {
                width: 30px;
                height: 30px;
              }
      
              &:hover {
                background-color: transparent;
                box-shadow: none;
                border-bottom: none;
              }
            }
          }
        }
   }
   