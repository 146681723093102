.login-prompt-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .login-prompt-modal {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    max-width: 400px;
    width: 90%;
    text-align: center;
  
    h3 {
      margin-top: 0;
      font-size: 1.5rem;
      color: #333;
    }
  
    p {
      color: #666;
      margin-bottom: 1.5rem;
    }
  }
  
  .login-prompt-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.8rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s;
  
    &:hover {
      background-color: #0056b3;
    }
  }
  
  .close-prompt-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    color: #666;
    padding: 5px;
  
    &:hover {
      color: #333;
    }
  }