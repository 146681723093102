.opBody{
    background-color: rgb(92, 92, 92);
}
.login-prompt-overlayy {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .login-prompt-modall {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 350px;
    width: 90%;
  
    .loginrequired {
      margin-top: 0;
      color: #5a6268;
    }
    .actionTypemessageOP{
        color: #5a6268;
        margin-top: 25px;
        margin-bottom: 15px;
    }
  
    .login-prompt-buttonss {
      display: flex;
      justify-content: center;
      gap: 20px;
      margin-top: 20px;
  
      .login-buttonn, .cancel-buttonn {
        padding: 8px 16px;
        border-radius: 4px;
        border: none;
        cursor: pointer;
      }
  
      .login-buttonn {
        background: #007bff;
        color: white;
        text-decoration: none;
  
        &:hover {
          background: #0056b3;
        }
      }
  
      .cancel-buttonn {
        background: #6c757d;
        color: white;
  
        &:hover {
          background: #5a6268;
        }
      }
    }
  }