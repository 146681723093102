.friendsContainer {
  background-color: #1d1d1d;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  margin: auto;

  .friendsWrap {
    width: 100%;

    .selectfrndBtn {
      display: flex;
      justify-content: space-around;
      margin-bottom: 20px;

      button {
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 5px;
        background-color: #292929;
        color: #fff;
        border: none;
        transition: background-color 0.3s, transform 0.3s;
        font-weight: bold;

        &:hover {
          background-color: #383838;
          transform: scale(1.05);
        }

        &.active {
          background-color: #444;
        }
      }
    }

    .frndsList {
      display: flex;
      flex-direction: column;
      gap: 15px;
      max-height: 100vh;
      overflow-y: auto;
      padding-right: 10px;
      scrollbar-width: thin;
      scrollbar-color: #444 #1d1d1d;

      .friendItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #292929;
        padding: 10px;
        border-radius: 5px;
        transition: background-color 0.3s;

        &:hover {
          background-color: #383838;
        }

        .userLink {
          display: flex;
          align-items: center;
          text-decoration: none;

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 15px;
          }

          span {
            color: #fff;
            font-weight: bold;
          }
        }

        .removeBtn {
          background-color: #e74c3c;
          color: #fff;
          border: none;
          padding: 8px 15px;
          border-radius: 5px;
          cursor: pointer;
          font-weight: bold;
          transition: background-color 0.3s;

          &:hover {
            background-color: #c0392b;
          }
        }
      }
    }
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: rgb(40, 38, 38);
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  
  .modal-content button {
    margin: 10px;
    padding: 5px 10px;
  }
}
