.report-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  
    .report-modal__content {
      background-color: #2a2a2a;
      padding: 30px;
      border-radius: 12px;
      width: 450px;
      box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4); /* Shadow for depth */
      color: #f5f5f5;
      text-align: center;
      animation: fadeIn 0.3s ease-in-out;
    }
  
    .report-modal__title {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 20px;
      color: #ffffff;
      border-bottom: 2px solid #4caf50; /* Accent color */
      padding-bottom: 10px;
    }
  
    .report-modal__checkboxes {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 20px;
  
      .report-modal__checkbox {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
  
        input {
          margin-right: 10px;
          accent-color: #4caf50; /* Custom checkbox color */
        }
  
        label {
          color: #dddddd;
          font-size: 16px;
        }
      }
    }
  
    .report-modal__textarea {
      width: 100%;
      height: 100px;
      padding: 10px;
      background-color: #3a3a3a;
      color: #f5f5f5;
      border: 1px solid #666;
      border-radius: 6px;
      margin-bottom: 20px;
      resize: none;
      font-size: 14px;
  
      .report-modal::placeholder {
        color: #aaaaaa;
      }
    }
  
    .report-modal__buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      .report-modal__submit-button {
        background-color: #4caf50;
        color: #ffffff;
        padding: 10px 20px;
        border: none;
        border-radius: 6px;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;
  
        .report-modal:hover {
          background-color: #45a048;
        }
  
        .report-modal:disabled {
          background-color: #666666;
          cursor: not-allowed;
        }
      }
  
      .report-modal__close-button {
        background: none;
        border: none;
        color: #ffffff;
        font-size: 20px;
        cursor: pointer;
        padding: 5px;
  
        .report-modal:hover {
          color: #ff5c5c;
        }
      }
    }
  }
  
  /* Keyframe for fade-in animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  