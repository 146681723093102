@import "../../App.scss";

.header {
      
     display: flex;
     justify-content: space-between;
     width: 100%;
     box-sizing: border-box; // add this line
     align-items: center;
     padding-left: 1vh;
     padding-right: 2vh;     
   
     .logo {
       max-width: 125px; // medium logo width
       
       img {
         width: 100%;
         height: auto;
         display: block;
       }
     }
   
     .searchIcn {
      display: flex;
      align-items: center;
      max-width: 30px;
      max-height: 30px;
      margin-left: 1rem;
      position: relative;
      cursor: pointer;
    
      &:hover {
        transform: scale(1.3) rotate(15deg); // Scale up and slightly rotate
        animation: pulse-glow 1s infinite; // Add a glowing pulse effect
        transition: transform 0.3s ease, filter 0.3s ease;
      }
   
       img {
         width: 100%;
         height: auto;
         display: block;
       }
     }


     @include mobile {
      .logo {
        max-width: 80px;
       }
      .notification {
        max-width: 25px;
        max-height: 25px;
      }
    }
  
    @include tablet-portrait {
      .logo {
        max-width: 100px;
      }
      .notification {
        max-width: 30px;
        max-height: 30px;
      }
    }
  
    @include tablet-landscape {
      .logo {
        max-width: 110px;
      }
      .notification {
        max-width: 32px;
        max-height: 32px;
      }
    }
   }
   