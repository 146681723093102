.reset-password-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 25px;
  margin-top: 15%;
  background: linear-gradient(145deg, #1a1a1a, #2a2a2a);
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  color: #f0f0f0;

  h2 {
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
    font-size: 24px;
    letter-spacing: 1px;
  }

  p {
    text-align: center;
    color: #b3b3b3;
    margin-bottom: 25px;
    font-size: 14px;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    input {
      padding: 12px;
      border: 1px solid #444;
      background: #333;
      color: #f0f0f0;
      border-radius: 6px;
      font-size: 16px;
      transition: border-color 0.3s ease;

      &:focus {
        border-color: #007bff;
        outline: none;
      }
    }

    button {
      padding: 12px;
      background: linear-gradient(145deg, #007bff, #0056b3);
      color: white;
      border: none;
      border-radius: 6px;
      font-size: 17px;
      cursor: pointer;
      box-shadow: 0 4px 8px rgba(0, 123, 255, 0.3);
      transition: background-color 0.3s ease, box-shadow 0.3s ease;

      &:hover {
        background-color: #0056b3;
        box-shadow: 0 6px 12px rgba(0, 123, 255, 0.4);
      }
    }
  }

  .send-otp-btn {
    margin-top: 15px;
    padding: 12px;
    background: linear-gradient(145deg, #28a745, #218838);
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 17px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(40, 167, 69, 0.3);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      background-color: #218838;
      box-shadow: 0 6px 12px rgba(40, 167, 69, 0.4);
    }
  }

  .timer {
    margin-top: 15px;
    text-align: center;
    color: #ff4d4d;
    font-weight: bold;
    font-size: 16px;
  }
}
