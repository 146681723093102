.share-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);  // Dark semi-transparent background
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  &__content {
    background-color: #1f2937;  // Darker background
    border-radius: 12px;
    padding: 24px;
    max-width: 400px;
    width: 100%;
    position: relative;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.8);
  }

  &__title {
    color: #f9fafb;  // Light text color
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 16px;
  }

  &__url-container {
    display: flex;
    margin-bottom: 18px;
    background-color: #374151;
    border-radius: 8px;
    padding: 6px;
  }

  &__url-input {
    flex-grow: 1;
    background-color: transparent;
    border: none;
    color: #e5e7eb;  // Light grey text
    padding: 8px;
    font-size: 14px;
    outline: none;
    border-radius: 8px;
  }

  &__copy-button {
    display: flex;
    align-items: center;
    background-color: #3b82f6;
    border: none;
    color: white;
    padding: 8px 12px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s;
    
    img {
      width: 18px;
      height: 18px;
      margin-right: 6px;
    }

    &:hover {
      background-color: #2563eb;  // Darker blue on hover
    }
  }

  &__social-buttons {
    display: flex;
    justify-content: space-around;
    margin-bottom: 16px;
  }

  &__social-button {
    background: none;
    border: none;
    cursor: pointer;
    text-align: center;
    transition: transform 0.2s;
    
    &:hover {
      transform: scale(1.1);
    }

    .icon {
      width: 40px;
      height: 40px;
      margin-bottom: 6px;
    }

    .name {
      color: #e5e7eb;  // Light grey for text
      font-size: 14px;
    }
  }

  &__close-button {
    position: absolute;
    top: 12px;
    right: 12px;
    background: none;
    border: none;
    font-size: 20px;
    color: #e5e7eb;  // Light color for "X"
    cursor: pointer;
    transition: color 0.2s;

    &:hover {
      color: #f87171;  // Red on hover
    }
  }
}
