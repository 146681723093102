@import "../../App.scss";

.profileContainer {
  max-width: 700px;
  margin: auto;
  padding: 20px;
  background: #1d1d1d;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  font-family: 'Arial', sans-serif;
  height: 100%;
  @include tablet-portrait {
    height: 100vh;
  }
  
  .userDetail {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    border-bottom: 2px solid #333;
    padding-bottom: 20px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: -15px;
      top: 0;
      width: 5px;
      height: 100%;
      background: linear-gradient(180deg, #ff6d00, #ff3d00);
      border-radius: 2px;
    }

    .profilePicture {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 25px;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
      transition: transform 0.3s;
      object-fit: cover;

      &:hover {
        transform: scale(1.1);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .userNameNBio {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .userName {
        font-size: 1.7rem;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 8px;

        .userHandle {
          font-size: 1.2rem;
          color: #e1e1e1;
        }
      }
    }

    .profMenu {
      width: 25px;
      margin-left: auto;
      cursor: pointer;
      transition: transform 0.4s;

      &:hover {
        transform: rotate(360deg);
      }
    }
  }

  .userInterest {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  
    > span { 
      margin: 5px;
      padding: 5px 10px;
      color: #FF6D00;
      background: #292929;
      border-radius: 5px;
      transition: background 0.3s;
    }
  }

  .otherDetail {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    border-bottom: 2px solid #333;
    padding-bottom: 25px;
    padding-top: 5px;
    background: #1d1d1d;
  
    .location, .profession, .friendsList {
      display: flex;
      align-items: center;
      gap: 10px; // Add space between image and text
      flex: 1;
      min-width: 150px;
      background: #2a2a2a;
      padding: 15px;
      border-radius: 10px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
      color: #ddd;
      transition: transform 0.3s;
      cursor: pointer;
  
      &:hover {
        transform: scale(1.05);
      }
  
      img.icon {
        width: 20px; // Adjust size for icons
        height: 20px;
        margin-right: 10px; // Space between icon and text
      }
  
      span {
        font-size: 1rem;
        color: #ddd;
      }
    }
  
    .friendsList {
      justify-content: center;
      flex: 1;
      background: #2a2a2a;
      padding: 15px;
      border-radius: 10px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
      color: #ddd;
      transition: transform 0.3s;
  
      &:hover {
        transform: scale(1.05);
      }
    }
  }
  
  

  .userPost {
    display: flex;
    justify-content: center;
    margin: 50px 0;

    .postHeading {
      .postText, .draftText {
        margin-right: 20px;
        font-size: 2rem;
        cursor: pointer;
        transition: color 0.3s, transform 0.3s;

        &:hover {
          color: #FF6D00;
          transform: translateY(-5px);
        }
      }

      .postText.active, .draftText.active {
        color: #FF6D00;
        border: 1px solid #ddd;
        padding: 2px 4px;
        border-radius: 4px;
      }
    }
  }

  .postGrid {
    margin-top: 30px;
    display: grid;
    background-color: #2a2a2a;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    border: 1px solid #444;
    padding: 10px;
    max-width: 500px;
    margin: 30px auto;
    border-radius: 15px;
    position: relative; // Add this for absolute positioning of no-content-message
    min-height: 220px; // Add this to ensure minimum height when empty
  
    .postImage {
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-radius: 10px;
      transition: transform 0.3s;
  
      &:hover {
        transform: scale(1.05);
      }
  
      @include mobile {
        height: 170px;
      }
    }
  
    // Add this new style for the no-content message
    .no-content-message {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 100%;
      color: #888;
      font-size: 1.2em;
      font-weight: 500;
    }
  }
  

  .post-menu-container {
    position: relative;
}

.post-menu-options {
    position: absolute;
    top: calc(100% + 20px);  /* Move the menu box 10px down from the icon */
    right: 0;
    background-color: #535353;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    transform: translateY(-15px) scale(0.95);
    opacity: 0;
    visibility: hidden;
    transition: all 0.35s ease;
    z-index: 9;

    &.visible {
        transform: translateY(0) scale(1);
        opacity: 1;
        visibility: visible;
    }

    button {
        display: flex;
        align-items: center;
        width: 25vh;
        padding: 12px 20px;
        background-color: transparent;
        border: none;
        color: #ffffff;
        text-align: left;
        font-size: 15px;
        cursor: pointer;
        transition: background-color 0.25s ease;

        &:hover {
            background-color: #333;
        }

        &:active {
            background-color: #444;
        }

        .icon {
            margin-right: 10px;
            font-size: 18px;
        }

        &:not(:last-child) {
            border-bottom: 1px solid #444;
        }
    }
}
}



//BreakPoint